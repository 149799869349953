import React from 'react';
import styled from 'styled-components';

const ViewSortingButton = ({graph_view,changeView}) => {

    return <Wrapper className='ms-auto'>
        <button className={`btn ${graph_view ? 'active':null}`} id='graph_view' onClick={changeView}>graph view</button>
        <button className={`btn ${!graph_view ? 'active':null}`} id='table_view'  onClick={changeView}>table view</button>
    </Wrapper>
};
const Wrapper = styled.section`
    .btn{
        margin: 0 2px;
        background:#8C9AB4;
        margin-bottom:10px;
       
    }
    .active{
        background: var(--clr-primary-1);
        color:var(--clr-primary-10);
    }
`
export default ViewSortingButton;
