import React from "react";
import { Link } from "react-router-dom";
import { Dropdown, Menu } from "semantic-ui-react";

const MenuDropDown = (props) => (
    <div style={{ position: "absolute", top: "50px", zIndex: "9999" }}>
    <Menu vertical>
      <Dropdown text="Environments" pointing="left" className="link item" onClick={e=>{}}>
        <Dropdown.Menu>
          <Dropdown.Item onClick={e=>{props.setShowMenu(!props.showMenu)}}><Link to='/'>Alarm</Link></Dropdown.Item>
          <Dropdown.Item onClick={e=>{props.setShowMenu(!props.showMenu)}}><Link to='/live'>Live</Link></Dropdown.Item>
          <Dropdown.Item onClick={e=>{props.setShowMenu(!props.showMenu)}}><Link to='/reports'>Reports</Link></Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Menu.Item onClick={e=>{props.setShowMenu(!props.showMenu)}}>Settings</Menu.Item>
      <Menu.Item onClick={e=>{props.setShowMenu(!props.showMenu)}}>Help center</Menu.Item>
    </Menu>
  </div>
);

export default MenuDropDown;
