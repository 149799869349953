
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import artboard from "../assets/artboard.png";
import cloud from "../assets/cloud.png";
import { useUserContext } from '../context/user_context';
const RegistrationPage = () => {

    const { login, loading } = useUserContext();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
  const user = JSON.parse(sessionStorage.getItem("user"));

    const submitHandler = (e) => {
        e.preventDefault();
        login(username, password);

    }
    useEffect(()=>{
        if(user)
          navigate("/")
      },[])


    return (
        <Wrapper>
            <div className='page-100 bg-white d-flex align-items-center justify-content-center'>
                <div className="section">
                    <div className="section-center">
                        <div className="row">
                        <div className="col-md-7 mx-auto pe-5 pt-5">
                                {/* {error && <Message variant='danger'>{error}</Message>} */}
                                {/* {loading && <div className="d-flex justify-content-center align-items-center">
                                    <strong>Logging In...</strong>
                                    <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
                                </div>} */}

                                <img style={{width:"100%", height:"70%"}} src={artboard}/>
                                <div className='d-flex justify-content-center'>
                                    <img style={{width:"22%"}} src={cloud}/>
                                </div>
                                
                            </div>
                            <div className="col-md-5 register-component shadow">
                                <p className='mb-4 font-weight-light text-dark fs-5'>Welcome !</p>
                                <span className='fs-2 fw-bolder text-dark'>Sign up to </span>
                                <p className='mt-2 text-dark fs-7 fw-bolder'>Factory Next Cloud </p>

                                <form onSubmit={submitHandler} style={{marginTop:"20px"}}>

                                    <div className="mb-4">
                                        <label htmlFor="exampleInputPassword1" className="form-label" style={{margin:"0"}}><h6>Email</h6></label> <br />
                                        <input type="text"
                                            value={username}
                                            onChange={e => setUsername(e.target.value)}
                                            className="form-control form-control-lg "
                                            placeholder='Enter username ...' />
                                    </div>
                                    <div className="mb-4">
                                        <label htmlFor="exampleInputPassword1" className="form-label" style={{margin:"0"}}><h6>User Name</h6></label> <br />
                                        <input type="text"
                                            value={username}
                                            onChange={e => setUsername(e.target.value)}
                                            className="form-control form-control-lg "
                                            placeholder='Enter username ...' />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="exampleInputPassword1" className="form-label" style={{margin:"0"}}><h6>Password</h6></label> <br />

                                        <input type="password"
                                            value={password}
                                            onChange={e => setPassword(e.target.value)}
                                            className="form-control form-control-lg"
                                            placeholder='Enter password ...' />
                                    </div>

                                    <div className="mb-3">
                                        <label htmlFor="exampleInputPassword1" className="form-label" style={{margin:"0"}}><h6>Confirm Password</h6></label> <br />

                                        <input type="password"
                                            value={password}
                                            onChange={e => setPassword(e.target.value)}
                                            className="form-control form-control-lg"
                                            placeholder='Enter password ...' />
                                    </div>

                                    <button type="submit" className="btn" style={{width:"100%", marginTop:"4%"}}>{loading ? 'Loading...' : 'Register'}</button>
                                    <div className="w-100 mt-3" >
                                        <p style={{color:"#7D7D7D", fontSize:"14px"}} className="text-center">
                                            Already have an account? <Link to="/login"><span className="fw-bolder text-dark">Login</span></Link>
                                        </p>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

const Wrapper = styled.section`

    form{
        margin-top: 50px;
        input{
            text-transform:initial ;
        }
      
    }


`

export default RegistrationPage
