import React from "react";
import styled from "styled-components";
const Footer = () => {
  const user = JSON.parse(sessionStorage.getItem("user"));

  return (
    <>
      {user ? (
        <Wrapper>
          <h6>
            &copy; {new Date().getFullYear()} FactoryNext | All rights reserved
            Arthor Ltd.
          </h6>
        </Wrapper>
      ) : null}
    </>
  );
};

const Wrapper = styled.footer`
  height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  text-align: center;
  span {
    color: #04122C;
  }
  h6 {
    color: #04122C;
    margin: 0.1rem;
    font-weight: 600;
    text-transform: none;
    line-height: 1.25;
    font-size: 0.8rem;
  }
  @media (min-width: 776px) {
    flex-direction: row;
  }
`;

export default Footer;
