import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import AuthUser from "../components/AuthUser";
import EnvMonitoring from "../components/EnvMonitoring";
import FloorTopbar from "../components/FloorTopbar";
import Motion from "../components/Motion";
import { useUserContext } from "../context/user_context";
import { intervalTime } from "../utils/constants";
import Card from "../components/Card";
import MachineStatus from "../components/MachineStatus";
import TodayMachine from "../components/TodayMachine";
import PerformanceIndicator from "../components/PerformanceIndicator";
import HighChartComponent from "../components/HighChartComponent";
import { SortPerformance } from "../components/SortPerformance";
import InactiveMachine from "../components/InactiveMachine";
import { Hero } from "../components/Hero";

const Overview = () => {
  const { user, http } = AuthUser();
  const [summary, setSummary] = useState([]);
  const [todaysSummary, setTodaysSummary] = useState([]);
  const [days, setDays] = useState(15);
  const { selectedFactoryId } = useUserContext();
  const [selectedFloorId, setSelectedFloorId] = useState("");
  const [selectedCompatment, setSelectedCompartment] = useState("");
  const location = useLocation();

  const updateDays = (days) => {
    setDays(parseInt(days));
  };

  const navigate = useNavigate();
  useEffect(() => {
    // console.log('user', user);
    if (!user) {
      navigate("/login");
    }
    if (user) {
      // console.log(window.location.href)
      if ((location.pathname = "/")) {
        navigate("/dashboard");
      }
    }
    return () => {};
  }, []);

  //get total machine summary
  const fetchTotalMachineSummary = async () => {
    try {
      const { data } =
        user &&
        selectedFactoryId &&
        (await http.get(
          `/factory-machine-summary?factory_id=${selectedFactoryId}`
        ));
      setSummary(data);
    } catch (error) {}
  };
  const fetchToDaysMachineSummary = async () => {
    try {
      const { data } =
        user &&
        selectedFactoryId &&
        (await http.get(
          `/daily-production-summary?factory_id=${selectedFactoryId}`
        ));
      // setTodaysSummary(data);
      setSeconds(data);
    } catch (error) {}
  };

  const setSeconds = (data) => {
    try {
      if (data.hours_left) {
        data.hr_left = convertDurationToSeconds(data.hours_left);
      }
      if (data.loss_time) {
        data.ls_time = convertDurationToSeconds(data.loss_time);
      }
      if (data.total_active_machine_hrs) {
        data.actv_mchn = convertDurationToSeconds(
          data.total_active_machine_hrs
        );
      }

      data.ttl_time = fromHrtoSec(data.total_production_hrs);
      setTodaysSummary(data);
    } catch (e) {
      console.log(e);
    }

    // console.log("todaysSummary:", todaysSummary);
  };
  function fromHrtoSec(hourString) {
    const hours = parseFloat(hourString); // Extract numeric value from string
    const seconds = hours * 60 * 60; // Convert hours to seconds
    return seconds;
  }
  function convertDurationToSeconds(durationString) {
    const components = durationString
      .split(/[hms]/)
      .filter((c) => c.length > 0);
    let seconds = 0;
    if (components.length > 0) {
      seconds += parseInt(components[components.length - 1], 10);
    }
    if (components.length > 1) {
      seconds += parseInt(components[components.length - 2], 10) * 60;
    }
    if (components.length > 2) {
      seconds += parseInt(components[components.length - 3], 10) * 60 * 60;
    }
    return seconds;
  }

  useEffect(() => {
    fetchTotalMachineSummary();
    fetchToDaysMachineSummary();
  }, [selectedFactoryId]);

  return (
    <Motion>
      <Wrapper className="page-100">
        <p
          className="fw-bolder text-dark pt-4 pb-4"
          style={{
            maxWidth: "1100px",
            margin: "0 auto",
            overflow: "visible",
            fontFamily: "Arial",
            paddingRight: "0.5rem",
          }}
        >
          <Hero />
        </p>

        <div className="section">
          <div className="section-center">
            <div>
              <p className="fw-bolder text-dark">Floor Summary</p>
              <div className="row mb-4">
                <div className="col-lg-6 col-xl-3">
                  <Card
                    title="Active Machines"
                    amount={summary.total}
                    data={[{ name: 1, value: todaysSummary.ttl_time }]}
                    colors={["#44C13C", "#daf3d8"]}
                  />
                </div>
                <div className="col-lg-6 col-xl-3">
                  <Card
                    title="Total Maintenance Events"
                    color="#07C17E"
                    amount={todaysSummary.total_active_machine_hrs}
                    data={[
                      { name: 2, value: todaysSummary.actv_mchn },
                      { name: 1, value: todaysSummary.ttl_time },
                    ]}
                    colors={["#3C61C1", "#C1D6FF"]}
                  />
                </div>
                <div className="col-lg-6 col-xl-3">
                  <Card
                    title="Predicted Failures"
                    color="#FEC400"
                    amount={todaysSummary.loss_time}
                    data={[
                      { name: 2, value: todaysSummary.ls_time },
                      { name: 1, value: todaysSummary.ttl_time },
                    ]}
                    colors={["#C13C3C", "#FBD9D9"]}
                  />
                </div>
                <div className="col-lg-6 col-xl-3">
                  <Card
                    title="Next Maintenance"
                    color="#E0125A"
                    amount={todaysSummary.hours_left}
                    data={[
                      { name: 2, value: todaysSummary.hr_left },
                      { name: 1, value: todaysSummary.ttl_time },
                    ]}
                    colors={["#FEC400", "#FBF8D9"]}
                  />
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <p className="fw-bolder text-dark text-start">
                Predictive Notifications
              </p>
              <div className="col-md-12 mb-4">
                <div
                  className="text-center"
                  style={{ backgroundColor: "#FF040417", height: "222px",position:"relative" }}
                >
                  <div style={{position:"absolute", top:"50%",left:"50%",transform:"translate(-50%,-50%)"}}>
                    <h5>Potential Failure in Machine 5 (Compressor)</h5>
                    <h2>in 38 Hour 07 Minutes</h2>
                    <button className="btn">View Details</button>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-12 mb-4">
                <div className="title">
                  <p className="fw-bolder text-dark text-start">
                    Maintenance Summary
                  </p>
                  <InactiveMachine />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </Motion>
  );
};

const Wrapper = styled.article`
  .btn{
    background-color: #4040F2;
    height: 38px;
    width: 195px;
    border-radius: 32px;
   
  }
`;

export default Overview;
