export const getUniqueValues = (data,type) => {
    let categories = data.map(item=>item[type]);
    return ['all',...new Set(categories)];
}


export const closestTime = (arr, time) => {
    return arr.length > 0 && arr.reduce(function(prev, curr) {
        return Math.abs(curr - time) < Math.abs(prev - time) ? curr : prev;
    });
}