export const links = [
    {
        id:1,
        text:'Environment Monitoring',
        url:'/'
    },
    {
        id:2,
        text:'live',
        url:'/live'
    },
    {
        id:3,
        text:'reports',
        url:'/reports'
    },
]

// base url 
// issues with http > https
export const URL = 'https://factorynext-back.arthor.com.bd/api/v2';

export const URL_FOR_PROTECTED_APIs = `${URL}`;

export const intervalTime = 10000;