import React, { useEffect, useState } from "react";
import { IoNotificationsOutline, IoSearchOutline } from "react-icons/io5";
import { Image } from "semantic-ui-react";
import styled from "styled-components";
import dIcon from "../assets/d_icon.svg";
import proPic from "../assets/johndoe.jpeg";
import { useAppContext } from "../context/app_context";
import { useUserContext } from "../context/user_context";
import AuthUser from "./AuthUser";
import MenuDropdownProfile from "./MenuDropdownProfile";
const NotificationButton = (props) => {
  const { http } = AuthUser();
  const { selectedFactoryId, changeSelectedFactoryId } = useUserContext();
  const [factories, setFactories] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const { closeSidebar } = useAppContext();

  const user = JSON.parse(sessionStorage.getItem("user"));

  useEffect(() => {
    if (user) {
      const myArray = Object.entries(user.factories).map(([key, value]) => ({
        name: value,
        id: key,
      }));
      setFactories(Object.values(myArray));
    }
  }, [selectedFactoryId]);

  const handleChange = (e) => {
    changeSelectedFactoryId(e);
    closeSidebar();
  };

  return (
    <Wrapper className="notification-btn-wrapper">
      {/* <SearchModal/>
    <Link to='/notification' className='notification-btn' onClick={closeSidebar}>

      <span className='notification-container'><FaBell />
        // <span className="notification-value">
        //   10
        // </span>
      </span>
    </Link> */}

      <div className="select-bar">
        {/* {user ? (
          <select
            name=""
            className="select-input"
            value={selectedFactoryId}
            onChange={handleChange}
          >
            <option value="" disabled>
              Select Factory
            </option>
            {factories &&
              factories.map((factory, idx) => (
                <option key={factory.id} value={factory.id}>
                  {factory.name}
                </option>
              ))}

            <option value="logout"> LOGOUT</option>
          </select>
        ) : (
          <Link
            to="/login"
            type="button"
            className="auth-btn d-flex justify-content-center"
            onClick={closeSidebar}
          >
            <p className="mt-1">Login</p>
            <img src={userImg} alt="" />
          </Link>
        )} */}

        <IoNotificationsOutline
          size={30}
          className="m-auto border rounded-circle p-2 me-1"
        />
        <IoSearchOutline
          size={30}
          className="m-auto border rounded-circle p-2 me-1"
        />
        <Image src={proPic} style={{ cursor: "pointer" }} avatar onClick={e=>setShowMenu(!showMenu)}/>
        {showMenu && <MenuDropdownProfile setShowMenu={setShowMenu} windowWidth={props.windowWidth}/>}
      </div>

      {/* {
      userInfo ? <div type='button' className="auth-btn d-flex justify-content-center">
        <div className="btn-group">
          <button type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
            {selectedFactoryId}
          </button>
          <ul className="dropdown-menu">
         {
           factories.map((f,idx)=>{
             return <li key={idx}><a className="dropdown-item" href="#">{f}</a></li>
           })
         }

            <li><hr className="dropdown-divider" /></li>
            <li><a onClick={logoutHandler} className="dropdown-item" href="#">Logout</a></li>
          </ul>
        </div>
      </div> :
        <Link to='/login' type='button' className="auth-btn d-flex justify-content-center" onClick={closeSidebar}>
          <p className='mt-1'>Login</p><img src={user} alt="" />
        </Link>

    } */}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .select-input {
    border: none;
    color: var(--clr-grey-1);
    background-color: transparent;
    width: 140px;
    cursor: pointer;
    padding: 5px;
    height: 34px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: transparent url(${dIcon}) no-repeat 100% center;
    /* background: transparent url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='24' height='24' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='white'/></g></svg>") no-repeat; */
    background-position: right -5px top 50%;
    /* background: url(https://stackoverflow.com/favicon.ico) 96% / 15% no-repeat; */
  }

  /* .notification-btn {
    color: var(--clr-grey-1);
    font-size: 1.5rem;
    letter-spacing: var(--spacing);
    color: var(--clr-grey-1);
    display: flex;
    align-items: center;
  }
  .notification-container {
    display: flex;
    align-items: center;
    position: relative;
    svg {
      height: 1.5rem;
      margin-left: 5px;
    }
  }
  .notification-value {
    position: absolute;
    top: -10px;
    right: -16px;
    background: var(--clr-primary-5);
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 0.75rem;
    color: var(--clr-white);
    padding: 12px;
  }
  .search-btn {
    display: flex;
    align-items: center;
    background: transparent;
    border-color: transparent;
    font-size: 1rem;
    cursor: pointer;
    color: var(--clr-grey-1);
    letter-spacing: var(--spacing);
    svg {
      margin-left: 5px;
    }
  } */

  small {
    color: var(--clr-grey-1);
  }
  .auth-btn img {
    border: 2px solid var(--clr-primary-1);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    text-align: center;
    margin-left: 10px;
    align-items: center;
  }
`;

export default NotificationButton;
