

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useUserContext } from '../context/user_context';
import { intervalTime } from '../utils/constants';
import AuthUser from './AuthUser';
import InactiveMachineTable from './InactiveMachineTable';

const InactiveMachine = () => {

    const [machines, setMachines] = useState([]);
    const [loading, setLoading] = useState(false);
    const { user,http } = AuthUser();
    const {selectedFactoryId} = useUserContext();

 
    const fetchInactiveMachines = async () => {

        setLoading(true);
        const { data } = selectedFactoryId &&  await http.get(`/inactive-machine-list?factory_id=${selectedFactoryId}`);
        setMachines(data);
        setLoading(false);
    }

    useEffect(() => {
        fetchInactiveMachines();
        const interval = setInterval(() => {
            fetchInactiveMachines();
        }, intervalTime);

        return () => clearInterval(interval);
    }, [selectedFactoryId])


    //no need to use loader while using setInterval
    return (
        <Wrapper>
            <InactiveMachineTable machines={machines}/>
        </Wrapper>
    );
}

export default InactiveMachine;



const Wrapper = styled.section`
// .b-table {
//   width: 100%;
//   border-collapse: collapse;
// //   border: 3px solid var(--clr-primary-1);
//   border-radius:8px !important;
//   height:300px;
//   overflow-y:auto;
//   tbody {
//     border-style:none !important;
//   }
//   thead th {
//     cursor: pointer;
//     position: sticky;
//     top: 0;
//     background-color:#F0F0F0;
//     white-space:nowrap ;
//   }
// }

`