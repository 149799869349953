import React, { useEffect, useState } from "react";
import { Menu } from "semantic-ui-react";
import { useUserContext } from "../context/user_context";

const MenuDropDownProfile = (props) => {
  const { selectedFactoryId, changeSelectedFactoryId } = useUserContext();
  const [factories, setFactories] = useState([]);
  const user = JSON.parse(sessionStorage.getItem("user"));

  const handleChange = (e) => {
   let event = {
    target: {
      value:''
    }
   };
    event.target.value = e
    changeSelectedFactoryId(event);
    // closeSidebar();
   props.setShowMenu(false);
  };
  useEffect(() => {
    if (user) {
      const myArray = Object.entries(user.factories).map(([key, value]) => ({
        name: value,
        id: key,
      }));
      setFactories(Object.values(myArray));
    }
  }, [selectedFactoryId]);

  // useEffect(()=>{
  //   console.log(factories);
  // }, [factories])

  return (
    // <div style={props.windowWidth>990? { position: "absolute", top: "50px", right:"15%", zIndex: "9999" }: { position: "absolute", top: "50px", right:"2vw", zIndex: "9999" }}>
      <div className="profile-menu">
      <Menu vertical>
        {factories &&
          factories.map((f) => {
            return <Menu.Item key={f.id} name={f.name} value={f.id} onClick={e=>handleChange(f.id)} />;
          })}
        <Menu.Item name="logout" onClick={e=>handleChange("logout")} />
      </Menu>
    </div>
  );
};
export default MenuDropDownProfile;
