import React from "react";
import Motion from "./Motion";
import SingleMachineProductionGraph from "./SingleMachineProductionGraph";
import { WiDaySnowWind, WiRaindrop } from "react-icons/wi";
import SingleMachineProductionGraph2 from "./SignleMachineProductionGraph2";

const GraphView = ({ report, loading, state, handleCheck, cTf }) => {
  if (report?.length < 1 || report?.length === undefined) {
    return (
      <div className="p-2 m-2">
        <h3 className="text-center my-4">No Data Found</h3>
        <h4 className="text-center my-4">Please Select a Location</h4>
      </div>
    );
  }
  const length = report?.length;

  const date1 = report && new Date(report[length - 1]?.date);
  const date2 = report && new Date(report[0]?.date);
  const diffTime = Math.abs(date2 - date1);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));


  return (
    <Motion>
      <h4>
        <WiDaySnowWind size={40} /> <span>Temperature</span>
      </h4>
      <SingleMachineProductionGraph
        report={report}
        diffDays={diffDays}
        loading={loading}
        state={state}
        handleCheck={handleCheck}
        cTf={cTf}
        title="temperature"
        link="https://www.pngfind.com/pngs/m/273-2733257_icon-weather-portal-comments-weather-icons-png-white.png"
        startDate={date1}
        endDate={date2}
      />
        <h4>
        <WiRaindrop size={40} /> <span>Humidity</span>
      </h4>
      <SingleMachineProductionGraph2
        report={report}
        diffDays={diffDays}
        loading={loading}
        state={state}
        handleCheck={handleCheck}
        cTf={cTf}
        title="Humidity"
        link="https://www.pngfind.com/pngs/m/273-2733257_icon-weather-portal-comments-weather-icons-png-white.png"
        startDate={date1}
        endDate={date2}
      />
      {/* <h4><WiRaindrop size={40} /> <span>humidity</span></h4>
    <SingleMachineProductionGraph2 report={report}  diffDays={diffDays} loading={loading} title='humidity' link='https://toppng.com/uploads/preview/kitchen-water-drop-humidity-icon-transparent-11553538097gjb6atyj0q.png'/> */}
    </Motion>
  );
};

export default GraphView;
