import React, { useRef,useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import networkgraph from "highcharts/modules/networkgraph";
import drilldown from "highcharts/modules/drilldown";
networkgraph(Highcharts);

export const HighChart = () => {
    const chartComponent = useRef(null);
    const [chartOptions, setChartOptions] = useState({
      title: {
        text: 'Monthly Average Temperature',
        x: -20 //center
      },
      subtitle: {
        text: 'Source: WorldClimate.com',
        x: -20
      },
      xAxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ]
      },
      yAxis: {
        title: {
          text: 'Temperature (°C)'
        },
        plotLines: [{
          value: 0,
          width: 1,
          color: '#808080'
        }]
      },
      tooltip: {
        valueSuffix: '°C'
      },
      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
        borderWidth: 0,
        showInLegend: false
      },
      series: [{
        marker: {
          fillColor: 'transparent',
          lineColor: Highcharts.getOptions().colors[0]
        },
        data: [...Array(12)].map(Math.random)
      }, {
        marker: {
          fillColor: 'transparent'
        },
        data: [...Array(12)].map(Math.random)
      }, {
    
        data: [...Array(12)].map(Math.random)
      }, {
        lineColor: 'red',
        data: [...Array(12)].map(Math.random)
      }]
      })
      const [chartConfig, setChartConfig] = useState(chartOptions);
    return(
        <HighchartsReact
          highcharts={Highcharts}
          options={chartConfig}
          containerProps={{ style: { height: 350 } }}
          allowChartUpdate={true}
        />
    )
}
