import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "semantic-ui-css/semantic.min.css";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import ProtectedRoute from "./components/ProtectedRoute";
import SideNav from "./components/SideNav";
import { AppProvider } from "./context/app_context";
import { UserProvider } from "./context/user_context";
import Error from "./pages/Error";
import LivePage from "./pages/LivePage";
import LoginPage from "./pages/LoginPage";
import Overview from "./pages/OverviewPage";

import RegistrationPage from "./pages/RegistrationPage";
import ReportPage from "./pages/ReportPage";

function App() {
  const user = JSON.parse(sessionStorage.getItem("user"));
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // if(window.innerWidth <990){
  //   styles = {
  //     '--sidenav-display':"none"
  //   }
  // }

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <BrowserRouter>
      <UserProvider>
        <AppProvider>
          <div className="row">
            {user && <div className={windowWidth < 990 ? "col-0 pe-0" : "col-2 pe-0"}>
              <SideNav />
            </div>}

            <div className={windowWidth < 990 ? "col-12 pe-0" : "col-10 pe-0"}>
              <Navbar />
              <div style={{ margin: " 0 auto" }}>
                <Routes>
                  <Route path="/login" element={<LoginPage />} />
                  <Route path="/" element={<ProtectedRoute />}>
                    <Route path="/" element={<Overview />} />
                    <Route path="/dashboard" element={<Overview />} />
                    <Route path="/reports" element={<ReportPage />} />
                    <Route path="/demo" element={<LivePage />} />
                  </Route>
                  <Route path="*" element={<Error />} />
                </Routes>
                <ToastContainer />
                <Footer />
              </div>
            </div>
          </div>
        </AppProvider>
      </UserProvider>
    </BrowserRouter>
  );
}

export default App;
