import React from "react";
import styled from "styled-components";
import PieChartCustom from "./charts/PieChartCustom";

const Card = ({ title, amount, color, children, data, colors }) => {
  return (
    <Wrapper>
      {/* {children} */}
      <div className="row ">
        <div className="col mt-3 p-0 ms-4 mb-1">
          <p className="text-start ps-2" style={{color:"#373F47", fontSize:"12px"}}>{title}</p>
          <p style={{color:"#373F47", fontSize:"13px"}} className="text-start ps-2 pt-2 fw-bold">
            {amount}
          </p>
        </div>

        <PieChartCustom className="col mb-4" data={data} colors={colors}/>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  font-family: var(--ff-primary);
  background-color: var(--clr-primary-11);
  border-radius: 8px;
  color: var(--clr-black-1);
  text-align: center;
  margin: auto 0;
  padding: 3px;
  p {
    margin: 5px 0;
  }
  margin-bottom: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 8px;
`;

export default Card;
