import { AiOutlineCalendar } from "react-icons/ai";
import machine from "../assets/machine.svg";
import useSortableData from "../hooks/useSortableData";

const InactiveMachineTable = ({ machines }) => {
  const { items, requestSort, sortConfig } = useSortableData(machines);
  const getClassNamesFor = (floor) => {
    if (!sortConfig) {
      return;
    }
    return sortConfig.key === floor ? sortConfig.direction : undefined;
  };

  //faults:
  // Pwr Dwn = E0125A
  // Yarn Brk = CAC555
  // Niddle Brk = EE874D
  const isMobileView = window.innerWidth < 900;

  const renderTh = () => {
    if (window.innerWidth < 900)
      return (
        <thead>
          mobile....
          <tr
            style={{
              backgroundColor: "#F0F0F0",
              height: "50px",
              marginLeft: "10px",
            }}
            className="text-center"
          >
            <th
              onClick={() => requestSort("id")}
              className="text-center"
              style={{ textTransform: "capitalize ", color: "#9A99A2" }}
            >
              Machine <i className="fas fa-angle-down"></i>
            </th>
            <th
              onClick={() => requestSort("id")}
              className={getClassNamesFor("id")}
              style={{ textTransform: "capitalize ", color: "#9A99A2" }}
            >
              Machine Brand <i className="fas fa-angle-down"></i>
            </th>
            <th
              onClick={() => requestSort("id")}
              className={getClassNamesFor("id")}
              style={{ textTransform: "capitalize ", color: "#9A99A2" }}
            >
              Activity <i className="fas fa-angle-down"></i>
            </th>

            {/* <th onClick={() => requestSort('type')}
                          className={getClassNamesFor('type')} style={{textTransform:"capitalize "}}>
                          Type <i className="fas fa-angle-down"></i>

                      </th> */}
            <th
              onClick={() => requestSort("last_activity")}
              className={getClassNamesFor("last_activity")}
              style={{ textTransform: "capitalize ", color: "#9A99A2" }}
            >
              Last Activity <i className="fas fa-angle-down"></i>
            </th>
            <th
              onClick={() => requestSort("floor")}
              className={getClassNamesFor("floor")}
              style={{ textTransform: "capitalize ", color: "#9A99A2" }}
            >
              Floor Details<i className="fas fa-angle-down"></i>
            </th>
            <th
              onClick={() => requestSort("floor")}
              className={getClassNamesFor("floor")}
              style={{ textTransform: "capitalize ", color: "#9A99A2" }}
            >
              Actions <i className="fas fa-angle-down"></i>
            </th>
          </tr>
        </thead>
      );
    else
      return (
        <thead>
          <tr
            style={{
              backgroundColor: "#F0F0F0",
              height: "50px",
              marginLeft: "10px",
            }}
            className="text-center"
          >
            <th
              onClick={() => requestSort("id")}
              className="text-center"
              style={{ textTransform: "capitalize ", color: "#9A99A2" }}
            >
              Machine <i className="fas fa-angle-down"></i>
            </th>
            <th
              onClick={() => requestSort("id")}
              className={getClassNamesFor("id")}
              style={{ textTransform: "capitalize ", color: "#9A99A2" }}
            >
              Machine Brand <i className="fas fa-angle-down"></i>
            </th>
            <th
              onClick={() => requestSort("id")}
              className={getClassNamesFor("id")}
              style={{ textTransform: "capitalize ", color: "#9A99A2" }}
            >
              Activity <i className="fas fa-angle-down"></i>
            </th>

            {/* <th onClick={() => requestSort('type')}
                          className={getClassNamesFor('type')} style={{textTransform:"capitalize "}}>
                          Type <i className="fas fa-angle-down"></i>

                      </th> */}
            <th
              onClick={() => requestSort("last_activity")}
              className={getClassNamesFor("last_activity")}
              style={{ textTransform: "capitalize ", color: "#9A99A2" }}
            >
              Last Activity <i className="fas fa-angle-down"></i>
            </th>
            <th
              onClick={() => requestSort("floor")}
              className={getClassNamesFor("floor")}
              style={{ textTransform: "capitalize ", color: "#9A99A2" }}
            >
              Floor Details<i className="fas fa-angle-down"></i>
            </th>
            <th
              onClick={() => requestSort("floor")}
              className={getClassNamesFor("floor")}
              style={{ textTransform: "capitalize ", color: "#9A99A2" }}
            >
              Actions <i className="fas fa-angle-down"></i>
            </th>
          </tr>
        </thead>
      );
  };

  return (
    <div style={{ height: "550px", overflowY: "scroll" }}>
      <table style={{ width: "100%", fontSize: "13px", height: "500px" }}>
        {renderTh()}
        <tbody>
          {items.map((item,idx) => (
            <tr
              key={idx}
              style={{ borderBottom: "1px solid #F0F0F0", height: "30px" }}
            >
              <td>
                <div className="d-flex justify-content-center">
                  <img
                    src={machine}
                    style={{ width: "20px", marginRight: "10px" }}
                  />
                  {item.machineId}
                </div>
              </td>
              <td> {item.type}</td>
              <td>
                <p
                  style={{
                    backgroundColor:
                      item.activity == "Active" ? "#0ED66A" : "#F17F70",
                    fontWeight: "Normal ",
                    borderRadius: "22px",
                    padding: "10px",
                    marginTop: "8px",
                    color: "white",
                    display: "inline-block",
                    fontSize: "13px",
                  }}
                >
                  {item.activity}
                </p>
              </td>
              {/* <td className={item.type === '12 GG' || item.type === '12GG' ? 'text-primary' : 'text-info'}>{item.type}</td> */}
              <td style={{ fontWeight: "normal", color: "#FF4E3D" }}>
                <p
                  style={{
                    backgroundColor: "#FFE8D7",
                    fontWeight: "bold ",
                    borderRadius: "22px",
                    padding: "10px",
                    marginTop: "8px",
                    color: "#A02D39",
                    display: "inline-block",
                    fontSize: "13px",
                  }}
                >
                  {item.last_activity ? item.last_activity : "0 hrs ago"}
                </p>
              </td>
              <td>{item.floor}</td>
              <td>
                <div className="text-muted">
                  <AiOutlineCalendar
                    size={20}
                    style={{ marginRight: "10px", color: "#D6DADE" }}
                  />
                  {item.cycle_start_time}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default InactiveMachineTable;
