import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import styled from "styled-components";
import SortView from "../components/SortView";
import ViewSortingButton from "../components/ViewSortingButton";
import { URL, intervalTime } from "../utils/constants";
import usePrevious from "../hooks/usePrevious";
import Motion from "../components/Motion";
import { useUserContext } from "../context/user_context";
import AuthUser from "../components/AuthUser";
import { axios_export_table_instance } from "../utils/axios";
import { Hero } from "../components/Hero";
import { toast } from "react-toastify";
import { TbTemperatureCelsius, TbTemperatureFahrenheit } from "react-icons/tb";
import { DownloadTableExcel } from "react-export-table-to-excel";
import TableData from "../components/TableData";
import { Skeleton } from "@material-ui/lab";

const ReportPage = ({ state, handleCheck, cTf }) => {
  const currentDate = moment().format("YYYY-MM-DD");
  const prevDate = moment().subtract(1, "days").format("YYYY-MM-DD");
  const [graphView, setGraphView] = useState(true);
  const { user, http } = AuthUser();
  const { selectedFactoryId } = useUserContext();
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [warehouseList, setWarehouseList] = useState([]);
  const [warehouseId, setWarehouseId] = useState(null);
  const [report, setReport] = useState([]);
  const [tabularReport, setTabularReport] = useState([]);
  const [startDate, setStartDate] = useState(prevDate);
  const [endDate, setEndDate] = useState(currentDate);
  const [deviceId, setDeviceId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingOnExport, setLoadingOnExport] = useState(false);
  const [loadingOnDownload, setLoadingOnDownload] = useState(false);
  const [loadingWareHouse, setLoadingWareHouse] = useState(false);
  const [thresoldValues, setThresoldValues] = useState({});
  const table_ref = useRef(null);

  // console.log(diffDays)
  const changeView = (e) => {
    const viewType = e.target.id;
    if (viewType === "table_view") {
      setGraphView(false);
    }
    if (viewType === "graph_view") {
      setGraphView(true);
    }
  };

  //fetch locations
  const fetchLocations = async () => {
    const { data } = await http.get(
      `/factory-floors?factory_id=${user.default_factory}`
    );
    let a = Object.entries(data).map(([key, value]) => ({ [key]: value }));
    data && setLocations(a);
  };

  useEffect(() => {
    fetchLocations();
  }, [selectedFactoryId]);

  const handleOnLocationChange = (e) => {
    setSelectedLocationId(parseInt(e.target.value));
    setSelectedLocation(e.target.options[e.target.selectedIndex].text);
    setWarehouseId(!warehouseId);
  };

  const handleOnWarehouseChange = (e) => {
    setWarehouseId(parseInt(e.target.value));
  };

  const handleOnStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleOnEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  //get warehouse information (id,identifier)
  const getAllWarehouse = async () => {
    setLoadingWareHouse(true);
    const { data } =
      selectedLocationId &&
      (await http.get(
        `/factory-machine-list?factory_id=${user.default_factory}&floor_id=${selectedLocationId}`
      ));
    data && data.length > 0 ? setWarehouseList(data) : setWarehouseList([]);
    setLoadingWareHouse(false);

    //set default deviceId
    data && data.length > 0
      ? setDeviceId(data[0].identifier)
      : setDeviceId(null);
    data && data.length > 0
      ? setWarehouseId(data[0].warehouseId)
      : setWarehouseId(null);
  };

  useEffect(() => {
    getAllWarehouse();
  }, [selectedFactoryId, selectedLocationId]);

  const fetchWeatherReport = async () => {
    try {
      setLoading(true);
      const res =
        selectedLocationId &&
        startDate &&
        endDate &&
        selectedFactoryId &&
        (await http.get(
          `${URL}/weather/report-tabular-floor?startDate=${startDate} 00:00:00&endDate=${endDate} 23:59:59&factory_id=${selectedFactoryId}&${
            selectedLocationId && `floor_id=${selectedLocationId}`
          }&limit=5000&offset=0`
        ));

      setThresoldValues(res?.machineDetails);
      setReport(res.data);
      setTabularReport(res.data);
      setLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    fetchWeatherReport();
  }, [startDate, endDate, selectedLocationId, selectedFactoryId]);

  return (
    <Motion>
      <Wrapper className="page-100">
        <Hero title="Reports of individual warehouses" />

        <div className="section">
          <div className="section-center">
            <div className="row">
              <div className="col col-lg-4">
                <div className="box">
                  <div className="row">
                    <label htmlFor="" className="col-6 my-1 col-lg-4">
                      {" "}
                      Location :{" "}
                    </label>
                    <div className="col-6 col-lg-8">
                      <select
                        disabled={loading}
                        name="location"
                        className="select-input"
                        id="locations"
                        onChange={(e) => handleOnLocationChange(e)}
                      >
                        <option value="">Select One</option>
                        {locations.map((location) => (
                          <option
                            key={Object.keys(location)}
                            value={Object.keys(location)}
                          >
                            {Object.values(location)}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="box">
                  <div className="row">
                    <label htmlFor="" className="col-6 my-1 col-lg-4">
                      From :{" "}
                    </label>
                    <div className="col-6 col-lg-8">
                      <input
                      disabled={loading}
                        type="date"
                        value={startDate}
                        onChange={(e) => handleOnStartDateChange(e)}
                        className="date"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-4">
                <div className="box">
                  <div className="row">
                    <label htmlFor="" className="col-6 my-1 col-lg-4">
                      To :{" "}
                    </label>
                    <div className="col-6 col-lg-8">
                      <input
                      disabled={loading}
                        type="date"
                        value={endDate}
                        onChange={(e) => handleOnEndDateChange(e)}
                        className="date"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="d-flex justify-content-between">
                <ViewSortingButton
                  changeView={changeView}
                  graph_view={graphView}
                />
              </div>

              {loading ? (
                <Skeleton variant="rect" width="100%" height={500} />
              ) : (
                <SortView
                  loading={loading}
                  report={report}
                  table_report={tabularReport}
                  thresoldValues={thresoldValues}
                  changeView={changeView}
                  graph_view={graphView}
                  state={state}
                  handleCheck={handleCheck}
                  cTf={cTf}
                  table_ref={report && table_ref}
                />
              )}
            </div>
          </div>

          <TableData loading={loading} report={report} graphView={graphView} />
        </div>
      </Wrapper>
    </Motion>
  );
};

const Wrapper = styled.section`
  .box {
    /* display:flex ;
  justify-content:space-between ; */
    text-align: start;
    padding: 5px;
    margin: 0 5px;
    label {
      white-space: nowrap;
      font-weight: 500;
      font-family: var(--ff-primary);
      /* text-align:end ; */
    }

    #locations,
    input {
      background: transparent;
      /* color: white; */

      padding: 5px;
      width: 150px;
      height: 30px;
      border: none;
      font-size: 14px;
      /* box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2); */
      /* -webkit-appearance: button; */
      /* appearance: button; */
      border-bottom: 1px solid var(--clr-primary-1);
      outline: none;
      cursor: pointer;
      text-transform: capitalize;
      font-weight: normal;
    }
  }

  .box select option {
    padding: 30px;
    text-align: center;
    cursor: pointer;
    text-transform: capitalize;
  }
`;
export default ReportPage;
