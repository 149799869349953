import React, { useRef,useState } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import networkgraph from "highcharts/modules/networkgraph";
import drilldown from "highcharts/modules/drilldown";
import styled from "styled-components";
networkgraph(Highcharts);

export const SemiPie = () => {
    const chartComponent = useRef(null);
    const [chartOptions, setChartOptions] = useState({
      colors:['#D9D9D9','#EB5757'],
        chart: {
          type: "pie",
          plotBackgroundColor: null,
        },
        credit: {
          enabled: false,
        },
        title: {
          text: "Chance of Failure in 60 hrs",
          style:{
            
            fontSize:"30px"
          }
        },
        xAxis: {
          categories: ["1 jan", "2 jan", "3 jan", "4 jan"],
        },
        yAxis: {
          gridLineWidth: 0,
          minorGridLineWidth: 0,
          allowDecimals: false,
          allowPointSelect: false,
          min: 0,
          max: 100,
         
        },
        // },
        legend: {
          enabled: false,
        },
        plotOptions: {
            pie: {
                dataLabels: {
                    enabled: false,
                    distance: -50,
                    style: {
                        fontWeight: 'bold',
                        color: 'red'
                    }
                },
                startAngle: -90,
                endAngle: 90,
                center: ['50%', '110%'],
                size: '190%'
            }
        },
        series: [
            {
                type: 'pie',
                name: 'Browser share',
                innerSize: '50%',
                data: [
                    ['Chrome', 83],
                    ['Edge', 17],
                  
                ]
            }
        ],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 500,
              },
              chartOptions: {
                legend: {
                  layout: "horizontal",
                  align: "center",
                  verticalAlign: "bottom",
                },
              },
            },
          ],
        },
        
      })
      const [chartConfig, setChartConfig] = useState(chartOptions);
    return(
        <HighchartsReact
          highcharts={Highcharts}
          options={chartConfig}
          containerProps={{ style: { height: 290 } }}
          allowChartUpdate={true}
        />
    )
}

const Wrapper = styled.section`
  tspan{
    font-size: 30px;
  }
`