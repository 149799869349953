import { useEffect, useState } from "react";
import filterFloor from "../assets/filterFloor.svg";
import { useUserContext } from "../context/user_context";
import AuthUser from "./AuthUser";
const FloorTopbar = (props) => {
  const { user, http } = AuthUser();
  const { selectedFactoryId, changeSelectedFactoryId } = useUserContext();
  const [floors, setFloors] = useState([]);
  const [displayFloors, setDisplayFloors] = useState("none");

  useEffect(() => {
    if (user) {
      props?.setSelectedFloorId(user.default_floor);
    }
  }, [user, props]);

  const setFloorsData = async () => {
    const { data } = await http.get(
      `/factory-floors?factory_id=${selectedFactoryId}&floor_id=${props.selectedFloorId}`
    );

    const myArray = Object.entries(data).map(([key, value]) => ({
      name: value,
      id: key,
    }));

    setFloors(myArray);

    if (myArray.length > 0) {
      props?.setSelectedFloorId(myArray[0].id);
    }

    //set default compartment
    const selectedFloor = myArray?.find(
      (floor) => floor.id == user?.default_floor
    );
    props?.setSelectedCompartment(selectedFloor?.name);
  };

  useEffect(() => {
    setFloorsData();
  }, [selectedFactoryId]);

  const handleSelectFloor = (value) => {
    // console.log("setSelectedFloorId:", value)
    props.setSelectedFloorId(value);
    const selectedFloor = floors?.find((floor) => floor.id === value);
    props.setSelectedCompartment(selectedFloor?.name);
    setDisplayFloors("none");
  };

  const options = [
    { key: "edit", icon: "edit", text: "Edit Post", value: "edit" },
    { key: "delete", icon: "delete", text: "Remove Post", value: "delete" },
    { key: "hide", icon: "hide", text: "Hide Post", value: "hide" },
  ];

  return (
    <div style={floorStyle}>
      <div
        className="d-flex justify-content-between"
        style={{
          maxWidth: "1100px",
          margin: "0 auto",
          overflow: "visible",
          // paddingLeft: "0.5rem",
          // paddingRight: "0.5rem",
        }}
      >
        <div className="d-flex justify-content-start">
          <span className="pt-2 pe-2  mt-2" style={{ fontWeight: "bold" }}>
           {props.label}
          </span>
          <div>
            <div
              className="d-flex justify-content-between border border-1 rounded-pill align-middle fw-bold"
              style={{
                width: "220px",
                marginTop: "6px",
                // marginLeft: "10px",
                fontSize: "13px",
                cursor: "pointer",
              }}
              onClick={(e) => {
                displayFloors == "none"
                  ? setDisplayFloors("block")
                  : setDisplayFloors("none");
              }}
            >
              <span className="ms-4 p-2 text-dark">
                12 January, 23 12:45 PM
              </span>
              <img
                src={filterFloor}
                style={{
                  width: "15px",
                  marginLeft: "2px",
                  marginRight: "10px",
                }}
              />
            </div>
          </div>
        </div>
       { props.passFrom === 'lineChart' && <div
          className="d-flex justify-content-end"
          style={{ cursor: "pointer" }}
        >
          <span className="fs-7 m-auto fw-bold">Historical Data</span>
        </div>}
      </div>
    </div>
  );
};
const floorStyle = {
  backgroundColor: "#FFFFFF",
  height: "50px",
  width: "100%",
  // marginTop: "10px",
  marginBottom: "10px",
  overflow: "visible",
  // border: "1px solid black",

  //   position:"relative"
};
const listFloor = (display) => {
  return {
    position: "relative",
    margin: "0 auto",
    // top: "50%",
    // left: "520px",
    backgroundColor: "#FFFF",
    width: "200px",
    zIndex: "9999",
    borderRadius: "5px",
    // padding: "10px",
    border: "1px solid #DEE2E6",
    display: display,
    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
    // display: "block",
  };
};
export default FloorTopbar;
