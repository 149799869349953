import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import AuthUser from "../components/AuthUser";
import FloorTopbar from "../components/FloorTopbar";
import { useUserContext } from "../context/user_context";
import { getLiveWeatherData } from "../utils/Api";
import { intervalTime } from "../utils/constants";
import HighChartComponent from "../components/HighChartComponent";
import { HighChart } from "../components/charts/HighChart";
import { SemiPie } from "../components/charts/SemiPie";
import { CorelationMatrix } from "../components/charts/CorelationMatrix";

const LivePage = () => {
  const [summary, setSummary] = useState([]);
  const [rht, setRht] = useState([]);
  const [smk, setSmk] = useState([]);
  const [flood, setFlood] = useState([]);
  const { selectedFactoryId } = useUserContext();
  const { user, http } = AuthUser();
  //Floor id
  const [selectedFloorId, setSelectedFloorId] = useState("");
  //Floor Name
  const [selectedCompatment, setSelectedCompartment] = useState("");

  const navigate = useNavigate();

  //get total machine summary
  const fetchTotalMachineSummary = async () => {
    getLiveWeatherData()
      .then((data) => {
        // console.log("live:",data)
        let smkTmp = [];
        let fldTmp = [];
        let rhtTmp = [];
        data.map((d, i) => {
          if (d.sensor.sensor == "SMK") {
            smkTmp.push({
              name: d.warehouse,
              temperature: d.temperature,
              is_alarm: d.is_alarm,
            });
          }
          if (d.sensor.sensor == "FLD") {
            fldTmp.push({
              name: d.warehouse,
              temperature: d.temperature,
              is_alarm: d.is_alarm,
            });
          }
          if (d.sensor.sensor == "RHT") {
            rhtTmp.push({
              name: d.warehouse,
              temperature: d.temperature,
              humidity: d.humidity,
              is_alarm: d.is_alarm,
            });
          }
          if (i == data.length - 1) {
            setSmk(smkTmp);
            setFlood(fldTmp);
            setRht(rhtTmp);
          }
        });
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchTotalMachineSummary();
    const interval = setInterval(() => {
      fetchTotalMachineSummary();
    }, intervalTime);

    return () => clearInterval(interval);
  }, [selectedFactoryId]);

  useEffect(() => {
    // console.log('user', user);

    if (!user) {
      navigate("/login");
    }
    return () => {};
  }, []);

  const sensors = JSON.parse(sessionStorage.getItem("sensors"));

  return (
    <div className="page-100">
      <div className="mt-1 mb-5">
        <FloorTopbar
          selectedFloorId={selectedFloorId}
          setSelectedFloorId={setSelectedFloorId}
          selectedCompatment={selectedCompatment}
          setSelectedCompartment={setSelectedCompartment}
          passFrom="lineChart"
          label="Fault Time"
        />
      </div>

      <div className="mt-4 mb-4">
        <div className="row">
          <h3 className="my-2 fw-bold" style={{ color: "#254175" }}>
            Detection History
          </h3>
          <div className="col">
            <HighChart />
          </div>
        </div>
      </div>

      <div className="mt-4 mb-4">
        <div className="row">
          <div className="col-md-6">
            <h3 className="my-2 fw-bold" style={{ color: "#254175" }}>
              Prediction
            </h3>
            <FloorTopbar
              selectedFloorId={selectedFloorId}
              setSelectedFloorId={setSelectedFloorId}
              selectedCompatment={selectedCompatment}
              setSelectedCompartment={setSelectedCompartment}
              label="Select Time"
            />
            <SemiPie />
            <div className="text-center" style={{position:"relative"}}>
              <h2 style={{
                position:"absolute",
                bottom:"120%",
                left:"0",
                right:"0",
                fontSize:"50px"

              }}>17%</h2>
              <h2>Root cause</h2>
              <h2 className="text-danger fw-bold" style={{fontSize:"66px"}}>Bearing Failure</h2>
            </div>
          </div>
          <div className="col-md-6">
            <h3 className="my-2 fw-bold" style={{ color: "#254175" }}>
              Correlation
            </h3>
            <div className="d-flex justidy-content-center">
              <FloorTopbar
                selectedFloorId={selectedFloorId}
                setSelectedFloorId={setSelectedFloorId}
                selectedCompatment={selectedCompatment}
                setSelectedCompartment={setSelectedCompartment}
                label="Start Time"
              />
              <FloorTopbar
                selectedFloorId={selectedFloorId}
                setSelectedFloorId={setSelectedFloorId}
                selectedCompatment={selectedCompatment}
                setSelectedCompartment={setSelectedCompartment}
                label="End Time"
              />
            </div>
            <CorelationMatrix />
          </div>
        </div>
      </div>
    </div>
  );
};

const Wrapper = styled.section`
  font-family: var(--ff-primary);

  .clr-btn {
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    margin-right: 0.1rem;
    border: none;
    margin: auto 0;
  }

  .machine-amount {
    color: var(--clr-primary-10);
    text-align: center;
    margin: auto 0;
    padding: 5px;
    p {
      margin: 5px 0 0 0;
    }
  }
`;
export default LivePage;
