import React, { useEffect, useState } from "react";
import { TiArrowSortedDown } from "react-icons/ti";
import { Link, useLocation } from "react-router-dom";
import overviewSelected from "../assets/alarm-selected.svg";
import overview from "../assets/alarm.svg";
import env from "../assets/env.svg";
import floor from "../assets/floor_map.svg";
import floorSelected from "../assets/floors-selected.svg";
import help from "../assets/help.svg";
import trnspLogo from "../assets/logo.svg";
import reportsSelected from "../assets/reports-selected.svg";
import reports from "../assets/reports.svg";
import settings from "../assets/settings.svg";

const SideNav = () => {
  const [isHoveredEnv, setIsHoveredEnv] = useState(false);
  const [isHoveredEng, setIsHoveredEng] = useState(false);
  const [isHoveredStg, setIsHoveredStg] = useState(false);
  const [isHoveredMcg, setIsHoveredMcg] = useState(false);
  const [show, setShow] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();
  const [styles, setStyles] = useState({});
  let dashboardBg = false;
  let demoBg=false;
  let reportBG = false
  if(location.pathname=='/' || location.pathname=='/dashboard' ){
    dashboardBg = true
  }else if(location.pathname=='/reports'){
    reportBG=true;
  }else{
    demoBg=true
  }

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  useEffect(()=>{
    if(windowWidth < 990){
      setStyles({
        "--sidenav-display": "none",
      })
    }else{
      setStyles({
        "--sidenav-display": "block",
      })
    }
  },[windowWidth])
  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <nav className=" side-nav fw-light" style={styles}>
      <ul style={{ listStyleType: "none", padding: "2px" }}>
        <li>
          <Link to="/">
            <img src={trnspLogo} />
          </Link>
        </li>
        <li style={{ marginBottom: "15px" }}>
          {/* <Link to="/live"> */}
          <div>
            <div
              className="d-flex justify-content-start"
              onClick={(e) => {
                setShow(!show);
              }}
              style={{ cursor: "pointer" }}
            >
              <img
                src={env}
                // onMouseOver={() => setIsHoveredEnv(true)}
                // onMouseOut={() => setIsHoveredEnv(false)}
                style={{ width: "20px", marginLeft: "10px" }}
              />
              <p
                style={{
                  textAlign: "start",
                  color: "white",
                  // marginRight: "10%",
                  marginLeft: "10%",
                  marginTop: "5px",
                }}
              >
                Maintenance
              </p>

              <TiArrowSortedDown
                style={{ color: "white", margin: "auto" }}
                size={30}
              />
            </div>

            <div
              style={{
                color: "white",
                marginLeft: "20%",
                display: show ? "block" : "none",
              }}
            >
              <Link to="/dashboard" className="hover-items">
                <div
                  className="d-flex justify-content-start p-2"
                  style={dashboardBg? { cursor: "pointer",  backgroundColor:"#254175" }:{ cursor: "pointer" }}
                >
                  <img
                    src={dashboardBg? floorSelected : floor}
                    // onMouseOver={() => setIsHoveredEnv(true)}
                    // onMouseOut={() => setIsHoveredEnv(false)}
                    style={{ width: "20px", marginLeft: "10px" }}
                  />
                  <p
                    style={dashboardBg?{
                      textAlign: "start",
                      color: "white",
                      marginLeft: "10%",
                      color: "#00E0FF"
                      // marginTop: "5px",
                    }:{
                      textAlign: "start",
                      color: "white",
                      marginLeft: "10%",
                      // color: "#00E0FF"
                      // marginTop: "5px",
                    }}
                  >
                    Dashboard
                  </p>
                </div>
              </Link>
              
              <Link to="/reports" className="hover-items">
                <div
                  className="d-flex justify-content-start p-2"
                  style={reportBG? { cursor: "pointer",  backgroundColor:"#254175" }: { cursor: "pointer"}}
                >
                  <img
                    src={reportBG? overviewSelected :overview}
                    // onMouseOver={() => setIsHoveredEnv(true)}
                    // onMouseOut={() => setIsHoveredEnv(false)}
                    style={{ width: "20px", marginLeft: "10px" }}
                  />

                  <p
                    style={reportBG?{
                      textAlign: "start",
                      color: "white",
                      marginLeft: "10%",
                      color: "#00E0FF"
                      // marginTop: "",
                    }:{
                      textAlign: "start",
                      color: "white",
                      marginLeft: "10%",
                      // color: "#00E0FF"
                      // marginTop: "",
                    }}
                  >
                    Reports
                  </p>
                </div>
              </Link>
              
              <Link to="/demo" className="hover-items">
                <div
                  className="d-flex justify-content-start p-2"
                  style={demoBg?{ cursor: "pointer",  backgroundColor:"#254175" }:{ cursor: "pointer" }}
                >
                  <img
                    src={demoBg?reportsSelected:reports}
                    // onMouseOver={() => setIsHoveredEnv(true)}
                    // onMouseOut={() => setIsHoveredEnv(false)}
                    style={{ width: "20px", marginLeft: "10px" }}
                  />
                  <p
                    style={demoBg?{
                      textAlign: "start",
                      color: "white",
                      marginLeft: "10%",
                      color: "#00E0FF"
                      // marginTop: "5px",
                    }:{
                      textAlign: "start",
                      color: "white",
                      marginLeft: "10%",
                      // color: "#00E0FF"
                      // marginTop: "5px",
                    }}
                  >
                    Demo
                  </p>
                </div>
              </Link>
            </div>
          </div>

          {/* </Link> */}
        </li>
        
        <li>
          <div
            className="d-flex justify-content-start"
            style={{ cursor: "pointer" }}
          >
            <img
              src={settings}
              // onMouseOver={() => setIsHoveredEnv(true)}
              // onMouseOut={() => setIsHoveredEnv(false)}
              style={{ width: "20px", marginLeft: "10px" }}
            />
            <p
              style={{
                textAlign: "start",
                color: "white",
                // marginRight: "10%",
                marginLeft: "10%",
                marginTop: "5px",
              }}
            >
              Settings
            </p>
          </div>
        </li>
        <li>
          <div
            className="d-flex justify-content-start mt-4"
            style={{ cursor: "pointer" }}
          >
            <img
              src={help}
              // onMouseOver={() => setIsHoveredEnv(true)}
              // onMouseOut={() => setIsHoveredEnv(false)}
              style={{ width: "20px", marginLeft: "10px" }}
            />
            <p
              style={{
                textAlign: "start",
                color: "white",
                // marginRight: "10%",
                marginLeft: "10%",
                marginTop: "5px",
              }}
            >
              Help center
            </p>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default SideNav;
